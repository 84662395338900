import { IConsoleMessage, IInternalProductCategories, ICdrProductCategories, ISectionTag } from  "@/modules/products/domain/types"
import moment from 'moment';
import store from '@/store';
import { productTabs } from '@/modules/products/services/EnumConverter';
import currencyCodes from 'currency-codes'
import * as validUrl from 'valid-url'

export const productValidator = function(productJSON: any): IConsoleMessage[] {
    let errorsArray: IConsoleMessage[] = [];
    checkForBasicFields(productJSON, errorsArray);
    if (productJSON.hasOwnProperty("additionalInformation")) checkForAdditionalInformationFields(productJSON, errorsArray);
    if (productJSON.hasOwnProperty("cardArt")) checkForCardArtFields(productJSON, errorsArray)
    if (productJSON.hasOwnProperty("features")) checkForFeatureFields(productJSON, errorsArray);
    if (productJSON.hasOwnProperty("bundles")) checkForBundleFields(productJSON, errorsArray);
    if (productJSON.hasOwnProperty("eligibility")) checkForEligibilityFields(productJSON, errorsArray);
    if (productJSON.hasOwnProperty("lendingRates")) checkForLendingFields(productJSON, errorsArray);
    if (productJSON.hasOwnProperty("depositRates")) checkForDepositFields(productJSON, errorsArray);
    if (productJSON.hasOwnProperty("fees")) checkForFeesFields(productJSON, errorsArray);
    if (productJSON.hasOwnProperty("constraints")) checkForConstraintFields(productJSON, errorsArray);
    if (productJSON.hasOwnProperty("x-pc-customTags")) checkForCustomTags(productJSON['x-pc-customTags'], errorsArray);
    if (productJSON.hasOwnProperty("x-pc-targetMarket")) checkForTargetMarket(productJSON, errorsArray);

    let internalCategory: any = getInternalCategory(productJSON)
    if (internalCategory && internalCategory.tabs) {
        let selectedInternalTabs = populateInternalTabs(internalCategory.tabs)
        let internalTabs;
        if (store.state.organisation.settings &&
            store.state.organisation.settings.internalProductTabs &&
            store.state.organisation.settings.internalProductTabs.tabs) {
            internalTabs = store.state.organisation.settings.internalProductTabs.tabs;
        }
        for (let tab of selectedInternalTabs) {
            if (internalTabs && Object.keys(internalTabs).length > 0) checkForCustomTags(productJSON[tab], errorsArray, true, internalTabs[tab].label, tab);
        }
    }
    return errorsArray;
}

function getInternalCategory(productJSON: any) {
    // Check for internal category
    if (store.state.organisation.settings &&
        store.state.organisation.settings.internalProductCategories &&
        store.state.organisation.settings.internalProductCategories.productCategories) {
        let internalCategories = store.state.organisation.settings.internalProductCategories.productCategories;
        let category = internalCategories.find((category: IInternalProductCategories) => productJSON.productCategory === category.key)
        if (category && Object.keys(category).length > 0) {
            return category;
        }
    }

    // Check for CDR category with additional tabs included
    if (store.state.organisation.settings &&
        store.state.organisation.settings.cdrProductCategories &&
        store.state.organisation.settings.cdrProductCategories.productCategories) {
        let cdrCategories = store.state.organisation.settings.cdrProductCategories.productCategories;
        let category = cdrCategories.find((category: ICdrProductCategories) => productJSON.productCategory === category.category)
        if (category && Object.keys(category).length > 0) {
            return category;
        }
    }

    return undefined;
}

function populateInternalTabs(selectedTabs: any[]) {
    let cdrTabs: { [index: string]: string } = {};
    for (let tab of productTabs) {
        cdrTabs[tab.key] = tab.label;
    }
    let internalTabs = selectedTabs.filter(tab => !cdrTabs.hasOwnProperty(tab))
    return internalTabs;
}

function checkForBasicFields(productJSON: any, errorsArray: IConsoleMessage[]): void {
    const mandatoryFields: any = [
        {
            key: "productCategory",
            humanFormat: "Product Category",
            enum: [
                "BUSINESS_LOANS",
                "CRED_AND_CHRG_CARDS",
                "LEASES",
                "MARGIN_LOANS",
                "OVERDRAFTS",
                "PERS_LOANS",
                "REGULATED_TRUST_ACCOUNTS",
                "RESIDENTIAL_MORTGAGES",
                "TERM_DEPOSITS",
                "TRADE_FINANCE",
                "TRAVEL_CARDS",
                "TRANS_AND_SAVINGS_ACCOUNTS"
            ]
        },
        {
            key: "name",
            humanFormat: "Name"
        },
        {
            key: "description",
            humanFormat: "Description"
        },
        {
            key: "brand",
            humanFormat: "Brand"
        },
        {
            key: "isTailored",
            humanFormat: "Product can be tailored by negotiation"
        }
    ]
    const otherFields: any = [
        {
            key: "effectiveFrom",
            humanFormat: "Effective From",
            pattern: "dateTime"
        },
        {
            key: "effectiveTo",
            humanFormat: "Effective To",
            pattern: "dateTime"
        },
        {
            key: "applicationUri",
            humanFormat: "Application URI",
            pattern: "uri"
        },
        {
            key: "x-pc-offMarket",
            humanFormat: "Off-Market",
            pattern: "boolean"
        }
    ]

    // Get internal categories from store for category checking
    if (store.state.organisation.settings &&
        store.state.organisation.settings.internalProductCategories &&
        store.state.organisation.settings.internalProductCategories.productCategories) {
            let internalCategories = store.state.organisation.settings.internalProductCategories.productCategories;
            internalCategories.map((category: IInternalProductCategories) => {
                mandatoryFields[0].enum.push(category.key);
            })
        }

    for (const manField of mandatoryFields) {
        validateMandatoryField(productJSON, manField, errorsArray, 'Product Details');
    }

    for (const othField of otherFields) {
        validateOtherField(productJSON, othField, errorsArray, 'Product Details');
    }

    // Validate the brand against the predefined values
    if (store.state.organisation.settings && store.state.organisation.settings.brands) {
        if (productJSON.brand && store.state.organisation.settings.brands.enforceBrands) {
            let found = false;
            let name:string|undefined;
            for (let brand of store.state.organisation.settings.brands.brands) {
                if (productJSON.brand === brand.id) {
                    found = true;
                    name = brand.name;
                    break;
                }
            }
            if (!found) {
                errorsArray.push({
                    icon: "exclamation-triangle",
                    source: "Schema Error",
                    type: "Error",
                    category: "Malformed Value",
                    message: `Value of "Brand" must be set to one of configured brands for the organisation`,
                    jsonKey: `(JSON Key - "brand")`,
                    section: 'Product Details'
                })
            } else {
                if (productJSON.brandName !== name) {
                    errorsArray.push({
                        icon: "exclamation-triangle",
                        source: "Schema Error",
                        type: "Error",
                        category: "Malformed Value",
                        message: `Value of "Brand Name" must be set to one of configured brands for the organisation`,
                        jsonKey: `(JSON Key - "brandName")`,
                        section: 'Product Details'
                    })
                }
            }
        }
    }

    // Validate the sync ID if sync enabled
    if (store.state.organisation.settings && store.state.organisation.settings.sync && store.state.organisation.settings.sync.enableSync && store.state.organisation.settings.sync.categories) {
        if ((store.state.organisation.settings.sync.categories as any)[productJSON.productCategory]) {
            if (!productJSON['x-pc-syncId'] || productJSON['x-pc-syncId'] === "") {
                errorsArray.push({
                    icon: "exclamation-triangle",
                    source: "Schema Error",
                    type: "Error",
                    category: "Missing Value",
                    message: `Synchronisation is enabled for this product category so a synchronisation ID must be entered.`,
                    jsonKey: `(JSON Key - "x-pc-syncId")`,
                    section: 'Product Details'
                })
            }
        }
    }

    // Validate the rate ID if rate calculation enabled
    if (store.state.organisation.settings && store.state.organisation.settings.calculation && store.state.organisation.settings.calculation.widgetworks && store.state.organisation.settings.calculation.widgetworks.includedCategories) {
        if ((store.state.organisation.settings.calculation.widgetworks.includedCategories as any)[productJSON.productCategory]) {
            if (!productJSON['x-pc-offMarket'] || store.state.organisation.settings.calculation.widgetworks.isOffMarketIncluded) {

                // Loop through each lending rate and ensure the rate ID is set and is unique
                if (productJSON.lendingRates) {
                    let ind = 0;
                    let jsonKey = 'lendingRates';
                    const rateIds: any = {};
                    for (const lendingRate of productJSON.lendingRates) {
                        // Check if rate ID is missing
                        if (!lendingRate['x-pc-rateId']) {
                            errorsArray.push(createErrorMessage('Unique Rate ID', 'x-pc-rateId', 0, [], "", [], 'lendingRates', ind, jsonKey));
                        } else {
                            // Check if rate ID is a duplicate
                            if (rateIds[lendingRate['x-pc-rateId']]) {
                                const error = createErrorMessage('Unique Rate ID', 'x-pc-rateId', 3, [], "", [], 'lendingRates', ind, jsonKey);
                                error.message = 'The lending rate ID is invalid.  The ID must be unique.';
                                errorsArray.push(error);
                            }
                            rateIds[lendingRate['x-pc-rateId']] = true;
                        }
                        ind++;
                    }
                }
            }
        }
    }
}

function checkForAdditionalInformationFields(productJSON: any, errorsArray: IConsoleMessage[]): void {
    const otherFields = [
        {
            key: "eligibilityUri",
            humanFormat: "Eligibility URI",
            pattern: "uri"
        },
        {
            key: "overviewUri",
            humanFormat: "Product Overview URI",
            pattern: "uri"
        },
        {
            key: "termsUri",
            humanFormat: "Terms and Conditions URI",
            pattern: "uri"
        },
        {
            key: "feesAndPricingUri",
            humanFormat: "Fees and Pricing URI",
            pattern: "uri"
        },
        {
            key: "bundleUri",
            humanFormat: "Bundle URI",
            pattern: "uri"
        }
    ]

    const jsonKey: string = "additionalInformation";

    for (const field of otherFields) {
        validateOtherField(productJSON.additionalInformation, field, errorsArray, 'Product Information');
    }
    if (productJSON.additionalInformation && productJSON.additionalInformation['x-pc-customTags']) checkForCustomTags(productJSON.additionalInformation['x-pc-customTags'], errorsArray, true, 'Product Details', 'additionalInformation');
}

function checkForCardArtFields(productJSON: any, errorsArray: IConsoleMessage[]): void {
    const mandatoryFields = [
        {
            key: "imageUri",
            humanFormat: "Card Image URI",
            pattern: "uri"
        }
    ]

    const otherFields = [
        {
            key: "title",
            humanFormat: "Card Title"
        }
    ]

    const jsonKey: string = "cardArt";

    productJSON.cardArt.map((cardArt: any, ind: number) => {
        for (const manField of mandatoryFields) {
            validateMandatoryField(cardArt, manField, errorsArray, 'Card Art', ind, jsonKey);
        }

        for (const othField of otherFields) {
            validateOtherField(cardArt, othField, errorsArray, 'Card Art', ind, jsonKey);
        }
        if (cardArt && cardArt['x-pc-customTags']) checkForCustomTags(cardArt['x-pc-customTags'], errorsArray, true, 'Card Art', 'cardArt');
    })
}

function checkForFeatureFields(productJSON: any, errorsArray: IConsoleMessage[]): void {
    const mandatoryFields = [
        {
            key: "featureType",
            humanFormat: "Feature Type",
            enum: [
                "CARD_ACCESS",
                "ADDITIONAL_CARDS",
                "UNLIMITED_TXNS",
                "FREE_TXNS",
                "FREE_TXNS_ALLOWANCE",
                "LOYALTY_PROGRAM",
                "OFFSET",
                "OVERDRAFT",
                "REDRAW",
                "INSURANCE",
                "BALANCE_TRANSFERS",
                "INTEREST_FREE",
                "INTEREST_FREE_TRANSFERS",
                "DIGITAL_WALLET",
                "DIGITAL_BANKING",
                "NPP_PAYID",
                "NPP_ENABLED",
                "DONATE_INTEREST",
                "BILL_PAYMENT",
                "COMPLEMENTARY_PRODUCT_DISCOUNTS",
                "BONUS_REWARDS",
                "NOTIFICATIONS",
                "FRAUD_PROTECTION",
                "CASHBACK_OFFER",
                "EXTRA_REPAYMENTS",
                "GUARANTOR",
                "INSTALMENT_PLAN",
                "RELATIONSHIP_MANAGEMENT",
                "OTHER"
            ]
        }
    ]

    const conditionalFields: any = [
        {
            key: "additionalValue",
            humanFormat: "Additional Value",
            parentKey: "featureType",
            parentValue: [
                'CARD_ACCESS',
                'ADDITIONAL_CARDS',
                'FREE_TXNS',
                'FREE_TXNS_ALLOWANCE',
                'LOYALTY_PROGRAM',
                'INSURANCE',
                'INTEREST_FREE',
                'INTEREST_FREE_TRANSFERS',
                'DIGITAL_WALLET',
                'BILL_PAYMENT',
                'COMPLEMENTARY_PRODUCT_DISCOUNTS',
                'BONUS_REWARDS',
                'CASHBACK_OFFER',
                'NOTIFICATIONS'
            ],
            amountFields: {
                fields: [
                    "ADDITIONAL_CARDS",
                    "FREE_TXNS",
                    "FREE_TXNS_ALLOWANCE",
                    "CASHBACK_OFFER"
                ],
                pattern: 'rate'
            }
        },
        {
            key: "additionalInfo",
            humanFormat: "Additional Info",
            parentKey: "featureType",
            parentValue: [
                'OTHER'
            ]
        }
    ]

    const otherFields: any = [
        {
            key: "additionalInfoUri",
            humanFormat: "Additional Info URI",
            pattern: "uri"
        }
    ]

    const jsonKey: string = "features";

    productJSON.features.map((feature: any, ind: number) => {
        for (const manField of mandatoryFields) {
            validateMandatoryField(feature, manField, errorsArray, 'Features', ind, jsonKey);
        }

        for (const conField of conditionalFields) {
            validateConditionalField(feature, conField, errorsArray, 'Features', ind, jsonKey); 
        }

        for (const othField of otherFields) {
            validateOtherField(feature, othField, errorsArray, 'Features', ind, jsonKey);
        }
        if (feature && feature['x-pc-customTags']) checkForCustomTags(feature['x-pc-customTags'], errorsArray, true, 'Features', 'features');
    })
}

function checkForBundleFields(productJSON: any, errorsArray: IConsoleMessage[]): void {
    const mandatoryFields = [
        {
            key: "name",
            humanFormat: "Name"
        },
        {
            key: "description",
            humanFormat: "Description"
        }
    ]

    const otherFields: any = [
        {
            key: "additionalInfo",
            humanFormat: "Additional Info"
        },
        {
            key: "additionalInfoUri",
            humanFormat: "Additional Info URI",
            pattern: "uri"
        },
        {
            key: "productIds",
            humanFormat: "Product IDs",
            pattern: "stringArray"
        }
    ]

    const jsonKey: string = "bundles";

    productJSON.bundles.map((bundle: any, ind: number) => {
        for (const manField of mandatoryFields) {
            validateMandatoryField(bundle, manField, errorsArray, 'Bundles', ind, jsonKey);
        }

        for (const othField of otherFields) {
            validateOtherField(bundle, othField, errorsArray, 'Bundles', ind, jsonKey);
        }
        if (bundle && bundle['x-pc-customTags']) checkForCustomTags(bundle['x-pc-customTags'], errorsArray, true, 'Bundles', 'bundles');
    })
}

function checkForEligibilityFields(productJSON: any, errorsArray: IConsoleMessage[]): void {
    const mandatoryFields = [
        {
            key: "eligibilityType",
            humanFormat: "Eligibility Type",
            enum: [
                "BUSINESS",
                "EMPLOYMENT_STATUS",
                "MAX_AGE",
                "MIN_AGE",
                "MIN_INCOME",
                "MIN_TURNOVER",
                "NATURAL_PERSON",
                "PENSION_RECIPIENT",
                "RESIDENCY_STATUS",
                "STAFF",
                "STUDENT",
                "OTHER"
            ]
        }
    ]

    const conditionalFields: any = [
        {
            key: "additionalValue",
            humanFormat: "Additional Value",
            parentKey: "eligibilityType",
            parentValue: [
                'MIN_AGE',
                'MAX_AGE',
                'MIN_INCOME',
                'MIN_TURNOVER',
                'EMPLOYMENT_STATUS',
                'RESIDENCY_STATUS'
            ],
            pattern: "wholeNumber"
        },
        {
            key: "additionalInfo",
            humanFormat: "Additional Info",
            parentKey: "eligibilityType",
            parentValue: [
                'OTHER'
            ]
        }
    ]

    const otherFields: any = [
        {
            key: "additionalInfo",
            humanFormat: "Additional Info"
        },
        {
            key: "additionalInfoUri",
            humanFormat: "Additional Info URI",
            pattern: "uri"
        }
    ]

    const jsonKey: string = "eligibility";

    productJSON.eligibility.map((eligibility: any, ind: number) => {
        for (const manField of mandatoryFields) {
            validateMandatoryField(eligibility, manField, errorsArray, 'Eligibility', ind, jsonKey);
        }

        for (const conField of conditionalFields) {
            if(conField.key === "additionalValue" && (eligibility.eligibilityType === 'MIN_INCOME' || eligibility.eligibilityType === 'MIN_TURNOVER')){
                conField['pattern'] = 'amount';
            }
            validateConditionalField(eligibility, conField, errorsArray, 'Eligibility', ind, jsonKey);
        }

        for (const othField of otherFields) {
            validateOtherField(eligibility, othField, errorsArray, 'Eligibility', ind, jsonKey);
        }
        if (eligibility && eligibility['x-pc-customTags']) checkForCustomTags(eligibility['x-pc-customTags'], errorsArray, true, 'Eligibility', 'eligibility');
    })
}

function checkForLendingFields(productJSON: any, errorsArray: IConsoleMessage[]): void {
    const mandatoryFields: any = [
        {
            key: "lendingRateType",
            humanFormat: "Lending Rate Type",
            enum: [
                "BUNDLE_DISCOUNT_FIXED",
                "BUNDLE_DISCOUNT_VARIABLE",
                "CASH_ADVANCE",
                "DISCOUNT",
                "FLOATING",
                "INTRODUCTORY",
                "MARKET_LINKED",
                "PENALTY",
                "PURCHASE",
                "VARIABLE",
                "FIXED"
            ]
        },
        {
            key: "rate",
            humanFormat: "Rate",
            pattern: "rate"
        }
    ]

    const conditionalFields: any = [
        {
            key: "additionalValue",
            humanFormat: "Additional Value",
            parentKey: "lendingRateType",
            parentValue: [
                'FIXED',
                'INTRODUCTORY',
                'DISCOUNT',
                'PENALTY',
                'FLOATING',
                'MARKET_LINKED',
                'BUNDLE_DISCOUNT_FIXED',
                'BUNDLE_DISCOUNT_VARIABLE'
            ]
        }
    ]

    const otherFields: any = [
        {
            key: "comparisonRate",
            humanFormat: "Comparison Rate",
            pattern: "rate"
        },
        {
            key: "calculationFrequency",
            humanFormat: "Calculation Frequency",
            pattern: "iso8601D"
        },
        {
            key: "applicationFrequency",
            humanFormat: "Application Frequency",
            pattern: "iso8601D"
        },
        {
            key: "interestPaymentDue",
            humanFormat: "Interest Payment Due",
            enum: [
                "IN_ADVANCE",
                "IN_ARREARS"
            ]
        },
        {
            key: "repaymentType",
            humanFormat: "Repayment Type",
            enum: [
                "INTEREST_ONLY",
                "PRINCIPAL_AND_INTEREST"
            ]
        },
        {
            key: "loanPurpose",
            humanFormat: "Loan Purpose",
            enum: [
                "OWNER_OCCUPIED",
                "INVESTMENT"
            ]
        },
        {
            key: "additionalInfo",
            humanFormat: "Additional Info"
        },
        {
            key: "additionalInfoUri",
            humanFormat: "Additional Info URI",
            pattern: "uri"
        }
    ]

    const mandatoryRateField = [
        {
            key: "name",
            humanFormat: "Name"
        },
        {
            key: "unitOfMeasure",
            humanFormat: "Unit Of Measure"
        },
        {
            key: "minimumValue",
            humanFormat: "Minimum Value",
            pattern: "number"
        }
    ]

    const otherRateFields = [
        {
            key: "maximumValue",
            humanFormat: "Maximum Value",
            pattern: "number"
        },
        {
            key: "rateApplicationMethod",
            humanFormat: "Rate Application Method"
        },
        {
            key: "additionalInfo",
            humanFormat: "Additional Info"
        },
        {
            key: "additionalInfoUri",
            humanFormat: "Additional Info URI",
            pattern: "uri"
        }
    ]

    const rateApplicabilityFields = [
        {
            key: "additionalInfo",
            humanFormat: "Additional Info"
        },
        {
            key: "additionalInfoUri",
            humanFormat: "Additional Info URI",
            pattern: "uri"
        }
    ]

    const comparisonFields = [
        {
            key: "maximumValue",
            humanFormat: "Maximum Value",
            comparedToKey: "minimumValue",
            humanFormatComparedKey: "Minimum Value",
            expect: 'MAX'
        }
    ]

    const jsonKey: string = "lendingRates";

    productJSON.lendingRates.map((lendingRate: any, ind: number) => {
        for (const manField of mandatoryFields) {
            validateMandatoryField(lendingRate, manField, errorsArray, 'Lending Rates', ind, jsonKey);
        }

        for (const conField of conditionalFields) {
            validateConditionalField(lendingRate, conField, errorsArray, 'Lending Rates', ind, jsonKey);
        }

        for (const othField of otherFields) {
            validateOtherField(lendingRate, othField, errorsArray, 'Lending Rates', ind, jsonKey);
        }
        if (lendingRate && lendingRate['x-pc-customTags']) checkForCustomTags(lendingRate['x-pc-customTags'], errorsArray, true, 'Lending Rates', 'lendingRates');
        if (lendingRate.hasOwnProperty("tiers") && lendingRate.tiers.length !== 0) {
            let jsonKey = "tiers";
            let nestingLevel = [
                {
                    key: "lendingRates",
                    ind
                }
            ]
            lendingRate.tiers.map((tier: any, ind: number) => {
                for (const manRateField of mandatoryRateField) {
                    validateMandatoryField(tier, manRateField, errorsArray, 'Lending Rates', ind, jsonKey, [], "", nestingLevel);
                }

                for (const othRateField of otherRateFields) {
                    validateOtherField(tier, othRateField, errorsArray, 'Lending Rates', ind, jsonKey, [], "", nestingLevel);
                }

                for(const comparisonField of comparisonFields) {
                    validateComparisonFields(tier,comparisonField, errorsArray, 'Lending Rates', ind, jsonKey, [], "", nestingLevel)
                }

                if (tier.hasOwnProperty("applicabilityConditions")) {
                    for (const rateAppField of rateApplicabilityFields) {
                        validateOtherField(tier.applicabilityConditions, rateAppField, errorsArray, 'Lending Rates', ind, jsonKey, [], "", nestingLevel);
                    }
                }
                if (tier && tier['x-pc-customTags']) checkForCustomTags(tier['x-pc-customTags'], errorsArray, true, 'Lending Rates', 'lendingRates', true, false, nestingLevel);
            })
        }
    })
}

function checkForDepositFields(productJSON: any, errorsArray: IConsoleMessage[]): void {
    const mandatoryFields: any = [
        {
            key: "depositRateType",
            humanFormat: "Deposit Rate Type",
            enum: [
                'FIXED',
                'BONUS',
                'BUNDLE_BONUS',
                'INTRODUCTORY',
                'FLOATING',
                'MARKET_LINKED',
                'VARIABLE'
            ]
        },
        {
            key: "rate",
            humanFormat: "Rate"
        }
    ]

    const conditionalFields: any = [
        {
            key: "additionalValue",
            humanFormat: "Additional Value",
            parentKey: "depositRateType",
            parentValue: [
                'FIXED',
                'BONUS',
                'BUNDLE_BONUS',
                'INTRODUCTORY',
                'FLOATING',
                'MARKET_LINKED'
            ]
        }
    ]

    const otherFields: any = [
        {
            key: "calculationFrequency",
            humanFormat: "Calculation Frequency",
            pattern: "iso8601D"
        },
        {
            key: "applicationFrequency",
            humanFormat: "Application Frequency",
            pattern: "iso8601D"
        },
        {
            key: "additionalInfo",
            humanFormat: "Additional Info"
        },
        {
            key: "additionalInfoUri",
            humanFormat: "Additional Info URI",
            pattern: "uri"
        }
    ]

    const mandatoryRateField = [
        {
            key: "name",
            humanFormat: "Name"
        },
        {
            key: "unitOfMeasure",
            humanFormat: "Unit Of Measure"
        },
        {
            key: "minimumValue",
            humanFormat: "Minimum Value",
            pattern: "number"
        }
    ]

    const otherRateFields = [
        {
            key: "maximumValue",
            humanFormat: "Maximum Value",
            pattern: "number"
        },
        {
            key: "rateApplicationMethod",
            humanFormat: "Rate Application Method"
        },
        {
            key: "additionalInfo",
            humanFormat: "Additional Info"
        },
        {
            key: "additionalInfoUri",
            humanFormat: "Additional Info URI",
            pattern: "uri"
        }
    ]

    const rateApplicabilityFields = [
        {
            key: "additionalInfo",
            humanFormat: "Additional Info"
        },
        {
            key: "additionalInfoUri",
            humanFormat: "Additional Info URI",
            pattern: "uri"
        }
    ]

    const jsonKey: string = "depositRates";

    productJSON.depositRates.map((depositRate: any, ind: number) => {
        for (const manField of mandatoryFields) {
            validateMandatoryField(depositRate, manField, errorsArray, 'Deposit Rates', ind, jsonKey);
        }

        for (const conField of conditionalFields) {
            validateConditionalField(depositRate, conField, errorsArray, 'Deposit Rates', ind, jsonKey);
        }

        for (const othField of otherFields) {
            validateOtherField(depositRate, othField, errorsArray, 'Deposit Rates', ind, jsonKey);
        }
        if (depositRate && depositRate['x-pc-customTags']) checkForCustomTags(depositRate['x-pc-customTags'], errorsArray, true, 'Deposit Rates', 'depositRates');
        if (depositRate.hasOwnProperty("tiers") && depositRate.tiers.length !== 0) {
            let jsonKey = "tiers";
            let nestingLevel = [
                {
                    key: "depositRates",
                    ind
                }
            ]
            depositRate.tiers.map((tier: any, ind: number) => {
                for (const manRateField of mandatoryRateField) {
                    validateMandatoryField(tier, manRateField, errorsArray, 'Deposit Rates', ind, jsonKey, [], "", nestingLevel);
                }

                for (const othRateField of otherRateFields) {
                    validateOtherField(tier, othRateField, errorsArray, 'Deposit Rates', ind, jsonKey, [], "", nestingLevel);
                }

                if (tier.hasOwnProperty("applicabilityConditions")) {
                    for (const rateAppField of rateApplicabilityFields) {
                        validateOtherField(tier.applicabilityConditions, rateAppField, errorsArray, 'Deposit Rates', ind, jsonKey, [], "", nestingLevel);
                    }
                }
                if (tier && tier['x-pc-customTags']) checkForCustomTags(tier['x-pc-customTags'], errorsArray, true, 'Deposit Rates', 'depositRates', true, false, nestingLevel);
            })
        }
    })
}

function checkForFeesFields(productJSON: any, errorsArray: IConsoleMessage[]): void {
    const mandatoryFields = [
        {
            key: "name",
            humanFormat: "Name"
        },
        {
            key: "feeType",
            humanFormat: "Fee Type",
            enum: [
                "DEPOSIT",
                "EVENT",
                "EXIT",
                "PAYMENT",
                "PERIODIC",
                "PURCHASE",
                "TRANSACTION",
                "UPFRONT",
                "VARIABLE",
                "WITHDRAWAL"
            ]
        }
    ]

    const conditionalFields1 = [
        {
            key: "amount",
            humanFormat: "Amount",
            pattern: "amount",
            oneof: [
                "amount",
                "balanceRate",
                "transactionRate",
                "accruedRate"
            ]
        },
        {
            key: "balanceRate",
            humanFormat: "Balance Rate",
            pattern: "rate",
            oneof: [
                "amount",
                "balanceRate",
                "transactionRate",
                "accruedRate"
            ]
        },
        {
            key: "transactionRate",
            humanFormat: "Transaction Rate",
            pattern: "rate",
            oneof: [
                "amount",
                "balanceRate",
                "transactionRate",
                "accruedRate"
            ]
        },
        {
            key: "accruedRate",
            humanFormat: "Accrued Rate",
            pattern: "rate",
            oneof: [
                "amount",
                "balanceRate",
                "transactionRate",
                "accruedRate"
            ]
        }
    ]

    const conditionalFields2 = [
        {
            key: "additionalValue",
            humanFormat: "Additional Value",
            parentKey: "feeType",
            parentValue: [
                "PERIODIC"
            ]
        }
    ]

    const otherFields: any = [
        {
            key: "accrualFrequency",
            humanFormat: "Accrual Frequency",
            pattern: "iso8601D"
        },
        {
            key: "currency",
            humanFormat: "Currency",
            pattern: "currency"
        },
        {
            key: "additionalInfo",
            humanFormat: "Additional Info"
        },
        {
            key: "additionalInfoUri",
            humanFormat: "Additional Info URI",
            pattern: "uri"
        }
    ]

    const mandatoryDiscountField = [
        {
            key: "description",
            humanFormat: "Description"
        },
        {
            key: "discountType",
            humanFormat: "Discount Type",
            enum: [
                "BALANCE",
                "DEPOSITS",
                "ELIGIBILITY_ONLY",
                "FEE_CAP",
                "PAYMENTS"
            ]
        }
    ]

    const conditionalDiscountFields = [
        {
            key: "amount",
            humanFormat: "Amount",
            pattern: "amount",
            oneof: [
                "amount",
                "balanceRate",
                "transactionRate",
                "accruedRate",
                "feeRate"
            ]
        },
        {
            key: "balanceRate",
            humanFormat: "Balance Rate",
            pattern: "rate",
            oneof: [
                "amount",
                "balanceRate",
                "transactionRate",
                "accruedRate",
                "feeRate"
            ]
        },
        {
            key: "transactionRate",
            humanFormat: "Transaction Rate",
            pattern: "rate",
            oneof: [
                "amount",
                "balanceRate",
                "transactionRate",
                "accruedRate",
                "feeRate"
            ]
        },
        {
            key: "accruedRate",
            humanFormat: "Accrued Rate",
            pattern: "rate",
            oneof: [
                "amount",
                "balanceRate",
                "transactionRate",
                "accruedRate",
                "feeRate"
            ]
        },
        {
            key: "feeRate",
            humanFormat: "Fee Rate",
            pattern: "rate",
            oneof: [
                "amount",
                "balanceRate",
                "transactionRate",
                "accruedRate",
                "feeRate"
            ]
        },
        {
            key: "additionalValue",
            humanFormat: "Additional Value",
            parentKey: "discountType",
            parentValue: [
                'BALANCE',
                'DEPOSITS',
                'PAYMENTS',
                'FEE_CAP'
            ]
        },
        {
          key: "eligibility",
          humanFormat: "Eligibility Conditions",
          parentKey: "discountType",
          parentValue: [
              'ELIGIBILITY_ONLY'
          ]
        }
    ]

    const otherDiscountFields: any = [
        {
            key: "additionalInfo",
            humanFormat: "Additional Info"
        },
        {
            key: "additionalInfoUri",
            humanFormat: "Additional Info URI",
            pattern: "uri"
        }
    ]

    const mandatoryEligibilityField: any = [
        {
            key: "discountEligibilityType",
            humanFormat: "Discount Eligibility Type",
            enum: [
                "BUSINESS",
                "EMPLOYMENT_STATUS",
                "INTRODUCTORY",
                "MAX_AGE",
                "MIN_AGE",
                "MIN_INCOME",
                "MIN_TURNOVER",
                "NATURAL_PERSON",
                "PENSION_RECIPIENT",
                "RESIDENCY_STATUS",
                "STAFF",
                "STUDENT",
                "OTHER"
            ]
        }
    ]

    const conditionalEligibilityFields: any = [
        {
            key: "additionalValue",
            humanFormat: "Additional Value",
            parentKey: "discountEligibilityType",
            parentValue: [
                'PENSION_RECIPIENT',
                'MIN_AGE',
                'MAX_AGE',
                'MIN_INCOME',
                'MIN_TURNOVER',
                'STUDENT',
                'EMPLOYMENT_STATUS',
                'RESIDENCY_STATUS',
                'INTRODUCTORY'
            ],
            pattern: "wholeNumber"
        },
        {
            key: "additionalInfo",
            humanFormat: "Additional Info",
            parentKey: "discountEligibilityType",
            parentValue: [
                'OTHER'
            ]
        }
    ]

    const otherEligibilityFields: any = [
        {
            key: "additionalInfoUri",
            humanFormat: "Additional Info URI",
            pattern: "uri"
        }
    ]

    const jsonKey: string = "fees";

    productJSON.fees.map((fee: any, feeInd: number) => {
        for (const manField of mandatoryFields) {
            validateMandatoryField(fee, manField, errorsArray, 'Fees & Discounts', feeInd, jsonKey);
        }

        if (fee.feeType && fee.feeType !== "VARIABLE") {
            for (const conField of conditionalFields1) {
                validateConditionalField(fee, conField, errorsArray, 'Fees & Discounts', feeInd, jsonKey);
            }
        }

        for (const conField of conditionalFields2) {
            validateConditionalField(fee, conField, errorsArray, 'Fees & Discounts', feeInd, jsonKey);
        }

        for (const othField of otherFields) {
            validateOtherField(fee, othField, errorsArray, 'Fees & Discounts', feeInd, jsonKey);
        }
        if (fee && fee['x-pc-customTags']) checkForCustomTags(fee['x-pc-customTags'], errorsArray, true, 'Fees & Discounts', 'fees');
        if (fee.hasOwnProperty("discounts") && fee.discounts.length > 0) {
            let jsonKey = "discounts";
            let nestingLevel = [
                {
                    key: "fees",
                    ind: feeInd
                }
            ]
            fee.discounts.map((discount: any, disInd: number) => {
                for (const manDisField of mandatoryDiscountField) {
                    validateMandatoryField(discount, manDisField, errorsArray, 'Fees & Discounts', disInd, jsonKey, [], "", nestingLevel);
                }

                for (const conDisField of conditionalDiscountFields) {
                    validateConditionalField(discount, conDisField, errorsArray, 'Fees & Discounts', disInd, jsonKey, [], "", nestingLevel)
                }

                for (const othDisField of otherDiscountFields) {
                    validateOtherField(discount, othDisField, errorsArray, 'Fees & Discounts', disInd, jsonKey, [], "", nestingLevel);
                }
                if (discount && discount['x-pc-customTags']) checkForCustomTags(discount['x-pc-customTags'], errorsArray, true, 'Fees & Discounts', 'fees', true, false, nestingLevel);

                if (discount.hasOwnProperty("eligibility") && discount.eligibility.length > 0) {
                    let jsonKey = "eligibility";
                    let nestingLevel = [
                        {
                            key: "fees",
                            ind: feeInd
                        },
                        {
                            key: "discount",
                            ind: disInd
                        }
                    ]
                    discount.eligibility.map((eli: any, eliInd: number) => {
                        for (const manEliField of mandatoryEligibilityField) {
                            validateMandatoryField(eli, manEliField, errorsArray, 'Fees & Discounts', eliInd, jsonKey, [], "", nestingLevel);
                        }

                        for (const conEliField of conditionalEligibilityFields) {
                            validateConditionalField(eli, conEliField, errorsArray, 'Fees & Discounts', eliInd, jsonKey, [], "", nestingLevel)
                        }

                        for (const othEliField of otherEligibilityFields) {
                            validateOtherField(eli, othEliField, errorsArray, 'Fees & Discounts', eliInd, jsonKey, [], "", nestingLevel);
                        }
                        if (eli && eli['x-pc-customTags']) checkForCustomTags(eli['x-pc-customTags'], errorsArray, true, 'Fees & Discounts', 'fees', true, true, nestingLevel);
                    })
                }
            })
        }
    })
}

function checkForConstraintFields(productJSON: any, errorsArray: IConsoleMessage[]): void {
    const mandatoryFields = [
        {
            key: "constraintType",
            humanFormat: "Constraint Type",
            enum: [
                "MIN_BALANCE",
                "MIN_LIMIT",
                "MAX_BALANCE",
                "MAX_LIMIT",
                "OPENING_BALANCE"
            ],
        },
        {
            key: "additionalValue",
            humanFormat: "Additional Value",
            pattern: "amount",
        }
    ]

    const otherFields: any = [
        {
            key: "additionalInfo",
            humanFormat: "Additional Info"
        },
        {
            key: "additionalInfoUri",
            humanFormat: "Additional Info URI",
            pattern: "uri"
        }
    ]

    const jsonKey: string = "constraints";

    productJSON.constraints.map((constraint: any, ind: number) => {
        for (const manField of mandatoryFields) {
            validateMandatoryField(constraint, manField, errorsArray, 'Constraints', ind, jsonKey);
        }

        for (const othField of otherFields) {
            validateOtherField(constraint, othField, errorsArray, 'Constraints', ind, jsonKey);
        }
        if (constraint && constraint['x-pc-customTags']) checkForCustomTags(constraint['x-pc-customTags'], errorsArray, true, 'Constraints', 'constraints');
    })
}

function checkForCustomTags(productJSON: any, errorsArray: IConsoleMessage[], isInternalField: boolean = false, sectionName: string = 'Custom Tags', sectionId: string = '', isChild: boolean = false, isSubChild: boolean = false, nestingLevel: any = []): void {
    let tags: any;
    let data = productJSON;
    const section = sectionName;
    if (isInternalField) {
        if (store.state.organisation.settings &&
            store.state.organisation.settings.sectionTags &&
            store.state.organisation.settings.sectionTags.tags) {
                tags = store.state.organisation.settings.sectionTags.tags;
                let sectionTag = tags.find((tag: any) => tag.sectionName === sectionId)
                if (isSubChild && sectionTag && sectionId === 'fees') {
                    tags = sectionTag.children.discount.children.eligibility.tags;
                } else if (isChild && sectionTag) {
                    if (sectionId === 'lendingRates' || sectionId === 'depositRates') {
                        tags = sectionTag.children.rateTier.tags
                    } else if (sectionId === 'fees') {
                        tags = sectionTag.children.discount.tags
                    }
                } else if (sectionTag) tags = sectionTag.tags;
                else return;
        } else {
            return;
        }
    } else {
        if (store.state.organisation.settings &&
            store.state.organisation.settings.customTags &&
            store.state.organisation.settings.customTags.tags) {
                tags = store.state.organisation.settings.customTags.tags;
        } else {
            return;
        }
    }

    for (let tag of tags) {
        if (tag.mandatory && !data) {
            errorsArray.push(createErrorMessage(tag.label, tag.key, 0, [], "", nestingLevel, section));
            continue;
        }
        let patternValid;
        let correctExample;
        switch (tag.type) {
            case 'IDENTIFIER':
                if(!data || !data[tag.key]) break;
                patternValid = isPatternValid(data[tag.key], "tagIdentifier").patternValid;
                correctExample = isPatternValid(data[tag.key], "tagIdentifier").correctExample
                if (!patternValid) errorsArray.push(createErrorMessage(tag.label, tag.key, 4, [], correctExample, nestingLevel, section));
                break;
            case 'DATE':
                if(!data || !data[tag.key]) break;
                patternValid = isPatternValid(data[tag.key], "date").patternValid;
                correctExample = isPatternValid(data[tag.key], "date").correctExample
                if (tag.mandatory && !patternValid) errorsArray.push(createErrorMessage(tag.label, tag.key, 4, [], correctExample, nestingLevel, section));
                break;
            case 'DATETIME':
                if(!data || !data[tag.key]) break;
                patternValid = isPatternValid(data[tag.key], "dateTime").patternValid;
                correctExample = isPatternValid(data[tag.key], "dateTime").correctExample
                if (tag.mandatory && !patternValid) errorsArray.push(createErrorMessage(tag.label, tag.key, 4, [], correctExample, nestingLevel, section));
                break;
            case 'BOOLEAN':
                if(!data || !data[tag.key]) break;
                patternValid = isPatternValid(data[tag.key], "boolean").patternValid;
                correctExample = isPatternValid(data[tag.key], "boolean").correctExample
                if (tag.mandatory && !patternValid) errorsArray.push(createErrorMessage(tag.label, tag.key, 4, [], correctExample, nestingLevel, section));
                break;
            case 'URL':
                if(!data || !data[tag.key]) break;
                patternValid = isPatternValid(data[tag.key], "uri").patternValid;
                correctExample = isPatternValid(data[tag.key], "uri").correctExample
                if (!patternValid) errorsArray.push(createErrorMessage(tag.label, tag.key, 4, [], correctExample, nestingLevel, section));
                break;
            case 'GROUP':
                if (tag && tag.tags && tag.tags.length > 0) {
                    if (data[tag.key]) {
                        for (let group of data[tag.key]) {
                            for (let subTag of tag.tags) {
                                if (subTag.mandatory && !group[subTag.key]) {
                                    errorsArray.push(createErrorMessage(subTag.label, subTag.key, 0, [], "", nestingLevel, section));
                                    continue;
                                }
                                if (subTag.type === 'DATE') {
                                    patternValid = isPatternValid(group[subTag.key], "date").patternValid;
                                    correctExample = isPatternValid(group[subTag.key], "date").correctExample
                                    if (subTag.mandatory && !patternValid) errorsArray.push(createErrorMessage(subTag.label, subTag.key, 4, [], correctExample, nestingLevel, section));
                                } else if (subTag.type === 'DATETIME') {
                                    patternValid = isPatternValid(group[subTag.key], "dateTime").patternValid;
                                    correctExample = isPatternValid(group[subTag.key], "dateTime").correctExample
                                    if (subTag.mandatory && !patternValid) errorsArray.push(createErrorMessage(subTag.label, subTag.key, 4, [], correctExample, nestingLevel, section));
                                } else if (subTag.type === 'IDENTIFIER') {
                                    patternValid = isPatternValid(group[subTag.key], "tagIdentifier").patternValid;
                                    correctExample = isPatternValid(group[subTag.key], "tagIdentifier").correctExample
                                    if (!patternValid) errorsArray.push(createErrorMessage(subTag.label, subTag.key, 4, [], correctExample, nestingLevel, section));
                                } else if (subTag.type === 'BOOLEAN') {
                                    patternValid = isPatternValid(group[subTag.key], "boolean").patternValid;
                                    correctExample = isPatternValid(group[subTag.key], "boolean").correctExample
                                    if (subTag.mandatory && !patternValid) errorsArray.push(createErrorMessage(subTag.label, subTag.key, 4, [], correctExample, nestingLevel, section));
                                } else if (subTag.type === 'URL') {
                                    patternValid = isPatternValid(group[subTag.key], "uri").patternValid;
                                    correctExample = isPatternValid(group[subTag.key], "uri").correctExample
                                    if (!patternValid) errorsArray.push(createErrorMessage(subTag.label, subTag.key, 4, [], correctExample, nestingLevel, section));
                                }
                            }
                        }
                    }
                }
            break;
        }
    }
}

function checkForTargetMarket(productJSON: any, errorsArray: IConsoleMessage[]): void {
    const mandatoryFields: any = [
        {
            key: "x-pc-targetMarket",
            humanFormat: "Target Market ID"
        }
    ]
    for (const manField of mandatoryFields) {
        validateMandatoryField(productJSON, manField, errorsArray, 'Target Market');
    }
}

function validateMandatoryField(
    parentObject: any,
    field: any,
    errorsArray: IConsoleMessage[],
    section?: string,
    ind?: number, jsonKey?: string,
    allowedValues: any = [],
    correctExample: string = "",
    nestingLevel: any = []): void {
    if (!parentObject.hasOwnProperty(field.key)) {
        errorsArray.push(createErrorMessage(field.humanFormat, field.key, 0, allowedValues, correctExample, nestingLevel, section, ind, jsonKey));
    } else if (field.hasOwnProperty("enum") && field.enum.indexOf(parentObject[field.key]) < 0) {
        errorsArray.push(createErrorMessage(field.humanFormat, field.key, 1, field.enum, correctExample, nestingLevel, section, ind, jsonKey));
    } else if (parentObject[field.key].length === 0) {
        errorsArray.push(createErrorMessage(field.humanFormat, field.key, 2, allowedValues, correctExample, nestingLevel, section, ind, jsonKey));
    } else if (field.hasOwnProperty('pattern')) {
        let { patternValid, correctExample } = isPatternValid(parentObject[field.key], field.pattern);
        if (!patternValid) {
            errorsArray.push(createErrorMessage(field.humanFormat, field.key, 3, allowedValues, correctExample, nestingLevel, section, ind, jsonKey));
        }
    }
}

function validateComparisonFields(
    parentObject: any,
    field: any,
    errorsArray: IConsoleMessage[],
    section?: string,
    ind?: number, jsonKey?: string,
    allowedValues: any = [],
    correctExample: string = "",
    nestingLevel: any = []): void {
    if (parentObject.hasOwnProperty(field.key) && parentObject.hasOwnProperty(field.comparedToKey)) {
        if (parentObject[field.key] > 0 && parentObject[field.comparedToKey] > 0) {
            let { isError } = compare(parentObject[field.key],parentObject[field.comparedToKey],field.expect);
            if (isError) {
                errorsArray.push(createErrorMessage(field.humanFormat, field.key,6,allowedValues,correctExample,nestingLevel,section,ind,jsonKey, field.expect,field.humanFormatComparedKey));
            }
        }
    }
}

function validateConditionalField(
    parentObject: any,
    field: any,
    errorsArray: IConsoleMessage[],
    section?: string,
    ind?: number, jsonKey?: string,
    allowedValues: any = [],
    correctExample: string = "",
    nestingLevel: any = []): void {
    if (field.hasOwnProperty("parentKey") && parentObject.hasOwnProperty(field.parentKey) && field.parentValue.indexOf(parentObject[field.parentKey]) > -1) {
        if (!parentObject.hasOwnProperty(field.key)) {
            errorsArray.push(createErrorMessage(field.humanFormat, field.key, 0, allowedValues, correctExample, nestingLevel, section, ind, jsonKey));
        } else if (parentObject[field.key].length === 0) {
            errorsArray.push(createErrorMessage(field.humanFormat, field.key, 2, allowedValues, correctExample, nestingLevel, section, ind, jsonKey));
        }else if(parentObject.hasOwnProperty(field.key) && field.hasOwnProperty("pattern") && (parentObject[field.parentKey] === 'MAX_AGE' || parentObject[field.parentKey] === 'MIN_AGE' || parentObject[field.parentKey] === 'MIN_INCOME' || parentObject[field.parentKey] === 'MIN_TURNOVER')){
            let { patternValid, correctExample } = isPatternValid(parentObject[field.key], field.pattern);
            if (!patternValid) {
                errorsArray.push(createErrorMessage(field.humanFormat, field.key, 3, allowedValues, correctExample, nestingLevel, section, ind, jsonKey));
            } else {
                return; // One value is valid. No need to check other oneof values along with current value
            }
        }
        if (field.hasOwnProperty("amountFields") && field.amountFields.fields.indexOf(parentObject[field.parentKey]) > -1 && parentObject.hasOwnProperty(field.key)) {
            if (field.amountFields.hasOwnProperty("pattern")) {
                let { patternValid, correctExample } = isPatternValid(parentObject[field.key], field.amountFields.pattern);
                if (!patternValid) {
                    errorsArray.push(createErrorMessage(field.humanFormat, field.key, 3, allowedValues, correctExample, nestingLevel, section, ind, jsonKey));
                }
            }
        }
    } else if (field.hasOwnProperty("oneof")) {
        if (parentObject.hasOwnProperty(field.key) && field.hasOwnProperty("pattern")) {
            let { patternValid, correctExample } = isPatternValid(parentObject[field.key], field.pattern);
            if (!patternValid) {
                errorsArray.push(createErrorMessage(field.humanFormat, field.key, 3, allowedValues, correctExample, nestingLevel, section, ind, jsonKey));
            } else {
                return; // One value is valid. No need to check other oneof values along with current value
            }
        }
        // Check if atleast one of oneof fields is not empty
        let parentKeys = Object.keys(parentObject);
        let atleastOneNonEmpty = false;
        field.oneof.map((key: string) => {
            if (parentKeys.indexOf(key) > -1 && parentObject[key] !== "") atleastOneNonEmpty = true;
        })
        if (!atleastOneNonEmpty) {
            errorsArray.push(createErrorMessage(field.humanFormat, field.key, 5, field.oneof, correctExample, nestingLevel, section, ind, jsonKey));
        }
    }
}

function validateOtherField(
    parentObject: any,
    field: any,
    errorsArray: IConsoleMessage[],
    section?: string,
    ind?: number, jsonKey?: string,
    allowedValues: any = [],
    correctExample: string = "",
    nestingLevel: any = []): void {
    if (parentObject.hasOwnProperty(field.key)) {
        if (field.hasOwnProperty("pattern")) {
            let { patternValid, correctExample } = isPatternValid(parentObject[field.key], field.pattern);
            if (!patternValid) {
                errorsArray.push(createErrorMessage(field.humanFormat, field.key, 3, allowedValues, correctExample, nestingLevel, section, ind, jsonKey));
            }
        }
        if (field.hasOwnProperty("enum") && field.enum.indexOf(parentObject[field.key]) < 0) {
            errorsArray.push(createErrorMessage(field.humanFormat, field.key, 1, field.enum, correctExample, nestingLevel, section, ind, jsonKey));
        }
    }
}

function compare(value1: any, value2:any , expect: any) {
    let isError:boolean = false
    switch (expect) {
        case "MAX":
            if (+value1 < +value2) isError = true
            break;
        case "MIN":
            if (+value1 > +value2) isError = true
            break;
    }
    return { isError };
}

function isPatternValid(value: any, patternType: string) {
    let patternValid;
    let correctExample = "";
    let pattern;
    switch (patternType) {
        case "uri":
            patternValid = isUrlValid(value);
            correctExample = "https://www.productcloud.com.au";
            break;
        case "dateTime":
            patternValid = moment(value, moment.ISO_8601).isValid();
            correctExample = "2007-05-01T15:43:00.12345Z";
            break;
        case "date":
            patternValid = moment(value, "YYYY-MM-DD", true).isValid();
            correctExample = "2007-05-01";
            break;
        case "tagIdentifier":
            pattern = /^[a-zA-Z0-9_-]+$/;
            patternValid = pattern.test(value as string);
            correctExample = "A1B-CD-E23";
            break;
        case "rate":
            pattern = /^(0(\.\d+)?|1(\.0+)?)$/;
            patternValid = pattern.test(value as string);
            correctExample = "12.345";
            break;
        case "iso8601D":
            pattern = /^(-?)P(?=\d|T\d)(?:(\d+)Y)?(?:(\d+)M)?(?:(\d+)([DW]))?(?:T(?:(\d+)H)?(?:(\d+)M)?(?:(\d+(?:\.\d+)?)S)?)?$/;
            patternValid = pattern.test(value as string);
            correctExample = "P1DT1H";
            break;
        case "number":
            correctExample = "1.23";
            patternValid = false;
            if (typeof (value) === "number") {
                patternValid = true;
            } else if (typeof (value) === "string" || value instanceof String) {
                if (!isNaN(parseFloat(value as string))) {
                    patternValid = true;
                }
            }
            break;
        case "wholeNumber":
            correctExample = "12";
            patternValid = false;
            if (typeof (value) === "number") {
                patternValid = true;
            } else if (typeof (value) === "string" || value instanceof String) {
                pattern = /^[-]?\d{1,16}$/;
                patternValid = pattern.test(value as string);
            }
            break;
        case "currency":
            patternValid = currencyCodes.codes().includes(value)
            correctExample = "AUD";
            break;
        case "amount":
            pattern = /^[-]?\d{1,16}[.]\d{2,16}$/;
            patternValid = pattern.test(value as string);
            correctExample = "12.34";
            break;
        case "boolean":
            patternValid = typeof (value) === "boolean";
            correctExample = "true";
            break;
        case "stringArray":
            patternValid = Array.isArray(value);
            correctExample = "[ 'string' ]";
            break;
    }
    return {
        patternValid,
        correctExample
    }
}

function isUrlValid(urlString: string): boolean {
    if (!urlString || urlString === "") return true;
    try {
        if(!validUrl.isWebUri(urlString)) return false
    } catch (_) {
        return false;
    }
    return true;
}

function createErrorMessage(humanFormat: string, key: string, category: number, allowedValues: string[] = [], correctExample: string = "", nestingLevel: any = [], section?: string, ind?: number, jsonKey?: string, comparisonExpect?: string, humanFormatComparedKey?: string): IConsoleMessage {
    /*
        Category Definitions for Error Messages:
        0: Missing mandatory field
        1: Value not in allowed values
        2: Cannot be empty
        3: Malformed Value
        4: Invalid Tag
        5: Atleast One
        6: Comparison of two fields
    */
    let errorMsg: string = "";
    let errorCategory: string = "";
    let jsonRef: string | undefined;
    switch (category) {
        case 0:
            errorCategory = "Missing Value";
            errorMsg = `Missing mandatory value: "${humanFormat}"`
            jsonRef = `JSON Key: "${key}"${(typeof ind !== 'undefined' && jsonKey) ? ' in element ' + (ind + 1).toString() + ' of ' + jsonKey : ''}.`;
            break;
        case 1:
            errorCategory = "Disallowed Value";
            errorMsg = `Value of "${humanFormat}" not allowed. Allowed values: ${allowedValues}.`;
            jsonRef = `JSON Key: "${key}"${(typeof ind !== 'undefined' && jsonKey) ? ' in element ' + (ind + 1).toString() + ' of ' + jsonKey : ''}.`;
            break;
        case 2:
            errorCategory = "Empty";
            errorMsg = `Key: "${humanFormat}" cannot be empty.`;
            jsonRef = `JSON Key: "${key}"${(typeof ind !== 'undefined' && jsonKey) ? ' in element ' + (ind + 1).toString() + ' of ' + jsonKey : ''}.`;
            break;
        case 3:
            errorCategory = "Malformed Value";
            errorMsg = `Value of "${humanFormat}" is malformed. Example of correct value - ${correctExample}.`;
            jsonRef = `JSON Key: "${key}"${(typeof ind !== 'undefined' && jsonKey) ? ' in element ' + (ind + 1).toString() + ' of ' + jsonKey : ''}.`;
            break;
        case 4:
            errorCategory = "Invalid Tag";
            errorMsg = `Value of tag "${humanFormat}" is invalid. Example of correct value - ${correctExample}.`;
            break;
        case 5:
            errorCategory = "Atleast One";
            errorMsg = `Key "${key}" is missing${(typeof ind !== 'undefined' && jsonKey) ? ' in element ' + (ind + 1).toString() + ' of ' + jsonKey : ''}. At least one of ${allowedValues} is mandatory.`;
            break;
        case 6:
            errorCategory = "Comparison Fail";
            errorMsg= `Value of "${humanFormat}" is ${comparisonExpect === 'MAX' ? 'smaller' : 'larger'} than "${humanFormatComparedKey}"`
            break;
        default:
            errorCategory = "";
            errorMsg = "";
    }

    if (nestingLevel.length > 0) {
        errorMsg += ` Element present in `;
        nestingLevel.map((level: any) => {
            errorMsg += `${level.key} (Index: ${level.ind}) -> `
        })
        errorMsg = errorMsg.slice(0, errorMsg.length - 4)
    }

    const errorBody = {
        icon: "exclamation-triangle",
        source: "Schema Error",
        type: "Error",
        category: errorCategory,
        message: errorMsg,
        jsonKey: jsonRef,
        section: section
    }

    return errorBody;
}
